<template>
  <div class="wrap__contents points-inner">
    <div class="toolbar">
      <div class="toolbar__items subnav">
        <select2
          v-model="selectedStatus"
          ref="selectStatus"
        >
          <option2
            :selected="selectedStatus === 'all'"
            value='all'
          >
            {{ translate(translations, 'all') }}
          </option2>

          <option2
            v-for="type in typesAnpther"
            :value="type.name"
            :key="type.id"
            :selected="selectedStatus === type.name"
          >
            {{ translate(translations, type.name.replace(/[-]/g, " ")) }}
          </option2>
        </select2>
      </div>

      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :dateBy="dateBy"
          :filterBy="filterBy"
          @trigger="trigger"
          @refresh="search"
          @clear="search"
        />
      </div>

      <div class="toolbar__items table-settings">
        <div class="toolbar-rightbtns">
          <button
            class="btn--tbl-settings"
            @click="showTblSettings = true"
          >
            <i class="ico--settings"></i>
          </button>
        </div>
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :isLoading="isDataLoading"
        :isEmpty="isEmpty"
      />

      <tbl
        ref="tbl"
        :dataLoading="isDataLoading"
        v-else
      >
        <template slot="head">
          <tbl-td
            name="no"
            :sort="true"
            @click.native="sort('id')"
            v-if="checkCol('no', tblSetting)"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            name="created-date"
            :sort="true"
            @click.native="sort('created_at')"
            v-if="checkCol('created_at', tblSetting)"
          >
            {{ translate(translations, 'created date') }}
          </tbl-td>

          <tbl-td
            name="login-id"
            :sort="true"
            @click.native="sort('users.login_id')"
            v-if="checkCol('login_id', tblSetting)"
          >
            {{ translate(translations, 'login id') }}
          </tbl-td>

          <tbl-td
            name="login-name"
            :sort="true"
            @click.native="sort('users.login_name')"
            v-if="checkCol('login_name', tblSetting)"
          >
            {{ translate(translations, 'login name') }}
          </tbl-td>


          <tbl-td
            name="old-point"
            :sort="true"
            @click.native="sort('old_point')"
            v-if="checkCol('old_point', tblSetting)"
          >
            {{ translate(translations, 'old point') }}
          </tbl-td>

          <tbl-td
            name="amount"
            :sort="true"
            @click.native="sort('amount')"
            v-if="checkCol('amount', tblSetting)"
          >
            {{ translate(translations, 'point') }}
          </tbl-td>

          <tbl-td
            name="new-point"
            :sort="true"
            @click.native="sort('new_point')"
            v-if="checkCol('new_point', tblSetting)"
          >
            {{ translate(translations, 'new point') }}
          </tbl-td>

          <tbl-td
            name="division"
            :sort="true"
            @click.native="sort('type')"
            v-if="checkCol('division', tblSetting)"
          >
            {{ translate(translations, 'division') }}
          </tbl-td>

          <tbl-td
            name="memo"
            :sort="true"
            @click.native="sort('memo')"
            v-if="checkCol('memo', tblSetting)"
          >
            {{ translate(translations, 'memo') }}
          </tbl-td>

        </template>

        <template slot="body">
          <tbl-row
            v-for="(point, x) in pointList"
            :key="x"
            :class="{'is-test-user': point.user ? point.user.isTester : false }"
          >
            <tbl-td
              name="no"
              v-if="checkCol('no', tblSetting)"
            >
              {{ generateNumber(x, params.page, params.rows, params.sort, count || 0) }}
            </tbl-td>

            <tbl-td
              name="created-date"
              v-if="checkCol('created_at', tblSetting)"
            >
              {{ point.created_at | date }}
            </tbl-td>

            <tbl-td
              name="login-id"
              v-if="checkCol('login_id', tblSetting)"
              @click.native="openUser(point.user.id, 'cash-history/all')"
            >
              <template v-if="point.user">
                {{ point.user.login_id }}
              </template>

              <template v-else>
                -
              </template>
            </tbl-td>

            <tbl-td
              name="login-name"
              v-if="checkCol('login_name', tblSetting)"
              @click.native="openUser(point.user.id, 'cash-history/all')"
            >
              <template v-if="point.user">
                {{ point.user.login_name }}
              </template>

              <template v-else>
                -
              </template>
            </tbl-td>


            <tbl-td
              name="old-point"
              v-if="checkCol('old_point', tblSetting)"
            >
              {{ point.old_point | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              name="amount"
              v-if="checkCol('amount', tblSetting)"
            >
              {{ point.amount | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              name="new-point"
              v-if="checkCol('new_point', tblSetting)"
            >
              {{ point.new_point | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              name="division"
              v-if="checkCol('division', tblSetting)"
            >
              {{ translate(translations, point.type) }}
            </tbl-td>

            <tbl-td
              name="memo"
              :isHtml="true"
              :logs="point.memo"
              v-if="point.memo"
            >
            </tbl-td>

            <tbl-td
              name="memo"
              v-else
            >
              -
            </tbl-td>

          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            :total="count"
            v-model="params.page"
            :rows="params.rows"
            @page="trigger"
          >
          </pagination>
        </template>
      </tbl>
    </div>

    <table-settings
      :translations="translations"
      :showTblSettings="showTblSettings"
      :allCols="allCols"
      :tblSetting="tblSetting"
      :activate="showTblSettings"
      v-if="modConfig.tableConfig"
      @activate="showTblSettings = $event"
      @load="showLoad()"
    >
    </table-settings>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'

//= components
import Checkbox from '@/components/base/Checkbox'
import Modal from '@/components/base/Modal'
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'

//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'
import Status from '@/assets/js/filters/Status'

//= translation
import { translations } from '@/assets/js/translations/Points'

export default {
  name: 'Points',

  components: {
    Checkbox,
    Pagination,
    Search,
    CheckDataList
  },

  mixins: [
    getListStatus
  ],

  filters: {
    FormatAmount,
    Status
  },

  data () {
    let params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      route: {},
      translations,
      params,
      type: '',
      status: '',

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      showTblSettings: false,

      filterBy: [
        {
          value: 'users.login_id',
          display: 'login id'
        },

        {
          value: 'users.login_name',
          display: 'login name'
        },
        {
          value: 'memo',
          display: 'memo'
        }
      ],

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        }
      ],
      tblSetting: 'points_list',
      allCols: [
        {
          display: 'no',
          value: 'no'
        },
        {
          display: 'created date',
          value: 'created_at'
        },
        {
          display: 'login id',
          value: 'login_id'
        },
        {
          display: 'login name',
          value: 'login_name'
        },
        {
          display: 'new point',
          value: 'new_point'
        },
        {
          display: 'old point',
          value: 'old_point'
        },
        {
          display: 'point',
          value: 'amount'
        },
        {
          display: 'division',
          value: 'division'
        },
        {
          display: 'memo',
          value: 'memo'
        }
        ,
        {
          display: 'ip',
          value: 'ip'
        },
        {
          display: 'admin ip',
          value: 'admin_ip'
        },

        {
          display: 'domain',
          value: 'domain'
        }

      ]
    }
  },

  watch: {
    '$route' (to) {
      this.type = to.params.type
      this.status = to.params.status || 'all'

      this.$refs.selectStatus.selected.label = this.translate(translations, this.status.split('-').join(' '))

      this.params = this.generate(to.query)
      this.search(this.generate(to.query))
    }
  },

  computed: {
    ...mapState('points', ['pointList', 'count', 'types']),

    typesAnpther () {
      if (this.$route.params.type === 'auto') {
        return this.types.filter(e => {
          return e.is_auto === true
        })
      } else if (this.$route.params.type === 'manual') {
        return this.types.filter(e => {
          return e.id === 12 || e.id === 17
        })
      } else if (this.$route.params.type === 'conversion') {
        return this.types.filter(e => {
          return e.id === 11
        })
      } else {
        return this.types
      }
    }
  },

  async created () {
    await this.getTypes()
    this.search()
  },

  methods: {
    ...mapActions('points', {
      getPoints: 'get',
      getTypes: 'getTypes'
    }),

    trigger (query) {
      if (_.isEqual(query, this.params)) {
        this.search()
        return
      }
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    clear () {},

    async sort (sortBy) {
      if (this.params.sort === 'asc') {
        this.params.sort = 'desc'
      } else {
        this.params.sort = 'asc'
      }

      this.params.sort_by = sortBy

      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      this.$router.push({ query: this.params })
    },

    async search (query) {
      this.params = query || this.params

      let status = this.types.filter(e => {
          return e.name ===  this.$route.params.status
      })

      if(status.length){
        this.params.status = status[0].id
      }
      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getPoints({
        params: this.params,
        type: this.$route.params.type
      })

      this.isDataLoading = false
      this.showDataLoading = false
      if (!this.pointList.length) {
        this.isEmpty = true
      }
    },

    openUser (id, subtype) {
      window.open(
        `${this.appUrl}/user/${id}/${subtype}`,
        '',
        `width=${1400},height=${window.innerHeight}`
      )
    }
  }
}
</script>
