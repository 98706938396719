import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    auto_in: '자동지급',
    'manual_in/out': '지급/회수',

    //= type
    all: '구분',
    bet: '배팅',
    cashback: '케시백',
    conversion: '포인트 전환',
    daily_first_deposit: '매일 첫 입금',
    daily_second_deposit: '매일일 두번째 입금',
    daily_third_deposit: '매일일 세번째 입금',
    deposit: '매일 입금 지급',
    events: '이벤트 지급',
    first_deposit: '가입 후 첫 입금',
    level: '레벨',
    lost: '낙첨 포인트',
    manual_in: '수동 지급',
    manual_out: '회수',
    manual_withdraw: '회수',
    second_deposit: '가입 후 두번째 입금',
    third_deposit: '가입 후 세번째 입금',
    win: '당첨 지급',
    type: '구분',
    new_coupon: '변경 쿠폰',
    old_coupon: '이전 쿠폰',
    coupon: '쿠폰',
    coupon_use: '쿠폰 사용',
    cash_in: '입금시 지급',
    bet_in: '낙첨시 지급',
    bet_lost: '낙첨시 지급',
    coupon_in: '쿠폰 지급',
    created: '등록일자',

    //= table
    edit_table_settings: '테이블 설정 수정',
    no: '번호',
    created_date: '등록일자',
    stamp_date: '출석일자',
    login_id: '아이디',
    login_name: '닉네임',
    new_point: '변경포인트',
    old_point: '이전포인트',
    point: '포인트',
    division: '구분',
    memo: '메모',
    admin_ip: '어드민 IP',
    domain: '도메인',
    admin_id: '어드민 ',
    modify: '수정',
    promo_win: '프로모 당첨',
    promo: '프로모',
    'first-deposit': '가입후 첫 충전 포인트',
    'second-deposit': '가입후 두번째 충전 포인트',
    'third-deposit': '가입후 세번째 충전 포인트',
    true: '출석',
    status: '상태',
    referral: '지인 충전 포인트',
    lost_referral: '지인 낙첨 포인트',
    commission_referral: '입금 포인트 (사용자 설정)',
    commission_lost_referral: '낙첨 포인트 (사용자 설정)',
    rollback: '마감 복원',
    referral_rollback: '마감 복원 (추천인)',
    'daily-first-deposit': '매일 첫 입금',
    'daily-second-deposit': '매일 두번째 입금',
    'daily-third-deposit': '매일 세번째 입금',
    lost_chance: '한번더 찬스',
    lost_inplay: '낙첨 인플레이',
    lost_mini: '낙첨 미니게임',
    promo_use: '프로모션 사용',

    point_bet: '포인트 배팅',
    point_bet_cancel: '포인트 배팅 취소',

    'lost-inplay': '인플레이 낙첨 포인트',
    'lost-mini': '미니게임 낙첨 포인트'
  }
}
