import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,

    auto_in: `auto in`,
    'manual_in/out': 'manual in/out',

    //= type
    all: `all`,
    bet: `bet`,
    cashback: `cashback`,
    conversion: `conversion`,
    daily_first_deposit: `daily first deposit`,
    daily_second_deposit: `daily second deposit`,
    daily_third_deposit: `daily third deposit`,
    deposit: `deposit`,
    events: `events`,
    first_deposit: `first deposit`,
    level: `level`,
    lost: `lost`,
    manual_in: `manual in`,
    manual_out: `manual out`,
    manual_withdraw: `manual withdraw`,
    referral: `referral`,
    second_deposit: `second deposit`,
    third_deposit: `third deposit`,
    win: `win`,
    type: `type`,
    new_coupon: `new coupon`,
    old_coupon: `old coupon`,
    coupon: `coupone`,
    coupon_use: `coupone use`,
    cash_in: `give of cash`,
    bet_in: `give of bet`,
    bet_lost: `give of lose`,
    coupon_in: `coupon in`,
    //= table
    edit_table_settings: `edit table settings`,
    no: `no`,
    created_date: `created`,
    created_stamp_date: `created stamp date`,
    login_id: `login id`,
    login_name: `login name`,
    new_point: `new point`,
    old_point: `old point`,
    point: `point`,
    division: `division`,
    memo: `memo`,
    admin_ip: `admin ip`,
    domain: `domain`,
    admin_id: `admin id`,

    modify: `modify`,
    rollback: `rollback`,
    promo_win: `promo win`,
    promo: `promo`,
    'first-deposit': `first deposit`,
    'second-deposit': `second deposit`,
    'third-deposit': `third deposit`,
    true: `attend`,
    status: `status`,

    'daily-first-deposit': 'daily first deposit',
    'lost-inplay': 'lost inplay',
    'lost-mini': 'lost mini'
  }
}
