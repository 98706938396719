import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    auto_in: `自動支給`,
    'manual_in/out': '支給/回収',

    //= type
    all: `区分`,
    bet: `ベッティング`,
    cashback: `キャッシュバック`,
    conversion: `転換`,
    daily_first_deposit: `日の最初の入金`,
    daily_second_deposit: `日の2番目の入金`,
    daily_third_deposit: `日の3番目の入金`,
    deposit: `日の入金支給`,
    events: `イベント支給`,
    first_deposit: `加入後最初の入金`,
    level: `レベル`,
    lost: `外れ　支給`,
    manual_in: `支給`,
    manual_out: `回収`,
    manual_withdraw: `回収`,
    referral: `推薦人 支給`,
    second_deposit: `加入後2番目の入金`,
    third_deposit: `加入後3番目の入金`,
    win: `勝ち 支給`,
    type: `区分`,
    new_coupon: `変更クーポン`,
    old_coupon: `以前のクーポン`,
    coupon: `クーポン`,
    coupon_use: `クーポン使用`,
    cash_in: `入金`,
    bet_in: `ベッティング`,
    bet_lost: `外れ`,
    coupon_in: `クーポン`,
    //= table
    edit_table_settings: `テーブル設定の修正`,
    no: `ナンバー`,
    created_date: `登録日時`,
    created_stamp_date: `出席日時`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    new_point: `変更ポイント`,
    old_point: `以前のポイント`,
    point: `ポイント`,
    division: `区分`,
    memo: `メモ`,
    admin_ip: `管理者IP`,
    domain: `ドメイン`,
    admin_id: `管理者ID`,

    modify: `修正`,
    rollback: `回転`,
    promo_win: `プロモ 勝ち`,
    promo: `プロモ`,
    'first-deposit': `加入後最初のチャージポイント`,
    'second-deposit': `加入後2番目のチャージポイント`,
    'third-deposit': `加入後3番目のチャージポイント`,
    true: `出席`,
    status: `状態`
  }
}
